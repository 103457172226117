import { useEffect, useState } from 'react'
import { useLocation, useRoutes } from 'react-router-dom'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { RecoilRoot } from 'recoil'
import jwt_decode from 'jwt-decode'
import Cookie from 'js-cookie'

import {
    AskCreditCard,
    Footer,
    GlobalSnackbar,
    Header,
    InitStores,
} from 'components/app'
import routes from './routes'
import defaultTheme, { themeMultiply, various } from 'utils/theme'
import { cookies } from 'utils/constants'
import { urls } from 'utils/constants'
import { makeStyles } from '@mui/styles'

const isAuthenticated = () => {
    const accessToken = Cookie.get(cookies.ACCESS_TOKEN)
    const refreshToken = Cookie.get(cookies.REFRESH_TOKEN)
    const token = refreshToken || accessToken

    let isExpired = false

    if (token) {
        let decodedToken = jwt_decode(token)
        let dateNow = new Date()

        if (
            decodedToken.exp <
            parseInt(dateNow.getTime().toString().slice(0, -3))
        ) {
            isExpired = true
        }
        return !isExpired
    } else {
        return false
    }
}

const useStyles = makeStyles(() => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${themeMultiply(various.padding, 2)} ${various.padding}`,
    },
}))

const App = () => {
    const classes = useStyles()
    const { pathname } = useLocation()

    const [theme, setTheme] = useState(defaultTheme)

    const routing = useRoutes(routes(isAuthenticated(), setTheme))

    const canDisplayHeaderAndFooter = !(
        pathname.includes(urls.auth.ACTIVATION) ||
        pathname.includes(urls.auth.LOGIN) ||
        pathname.includes(urls.auth.REGISTER) ||
        pathname.includes(urls.auth.RESET_PASSWORD)
    )

    useEffect(() => {
        const html = document.querySelector('html')
        const body = document.querySelector('body')
        const root = document.querySelector('#root')

        if (!html || !body || !root) return

        body.style.width = '100%'
        body.style.height = '100%'
        body.style.margin = '0'
        body.style.color = theme.palette.secondary.main
        body.style.backgroundColor = theme.palette.primary.main

        root.style.display = 'flex'
        root.style.flexDirection = 'column'
        root.style.width = '100%'
        root.style.minHeight = '100vh'
    }, [theme])

    return (
        <RecoilRoot>
            <ThemeProvider theme={theme}>
                <StyledEngineProvider injectFirst>
                    <CssBaseline />

                    <InitStores setTheme={setTheme} />
                    <GlobalSnackbar />
                    <AskCreditCard />

                    {canDisplayHeaderAndFooter && <Header />}

                    <main className={classes.main}>{routing}</main>

                    {canDisplayHeaderAndFooter && <Footer />}
                </StyledEngineProvider>
            </ThemeProvider>
        </RecoilRoot>
    )
}

export default App
